/* Global App JS */

/*
 * Detect the user's timezone and set cookie
 */
jQuery(document).ready(function () {

  $('.app-confirm-submit').click(function($e) {
    return confirm($(this).attr('data-confirm-submit'));
  });

  function setTimezoneCookie(timezone) {
    var date = new Date(2099, 1, 1);
    document.cookie='timezone=' + timezone + '; path=/; expires=' + date.toUTCString();
  }

  setTimezoneCookie(jstz.determine().name());

  $('table').delegate('tr', 'click', function(e) {
    var href = $(this).find("a").attr("href");
    if(href) {
      window.location = href;
    }
  });

  /*
   Table Searches
   */
  var activeSystemClass = $('.list-group-item.active');

  $('.section-selection tr').click(function() {
    var checkbox = $(this).find(':first-child');
    checkbox.prop('checked', !checkbox.prop('checked'));
  });

  searchTable = $('.table-list-search').dataTable({
    'lengthChange': false,
    "sDom": '<"top">t<"bottom"ilp<"clear">>',
    "pageLength": 15,
    "order": [
      [ 0, 'asc' ],
      [ 2, 'asc' ]
    ]
  });

  $('#system-search').keyup(function() {
    searchTable.fnFilter($(this).val());
  });

  searchTable.delegate('tr', 'click', function(e) {
    var href = $(this).find("a").attr("href");
    if(href) {
      window.location = href;
    }
  });

  /*
   Goals and Objectives
   */
  $('#add-goal').on('click', function() {
    var html = "<div class='row'> <div class='col-md-6'> <h4><label class='label-font' for='goals'>GOAL</label></h4> <input type='text' class='form-control list-font' id='goals' name='goals[]'> </div> </div> <div class='row'> <div class='col-md-6'> <h4><label class='label-font' for='objectives'>OBJECTIVE</label></h4> <input type='text' class='form-control list-font' id='objectives' name='objectives[]'> </div> </div>";
    $("#goals").append(html);
  });

  $('#add-timestudy').on('click', function() {
    var html = " <div class='col-md-2'> <h4 class='header-font'><label for='timestudies'>ENTER TIME</label></h4> <input type='number' min='0' class='form-control list-font' id='time_studies' name='time_studies[]'> </div>";
    $("#timestudies").append(html);
  });

});
